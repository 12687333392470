<template>
  <div id="smart-button-container" class="s-wrapper-btn">
    <div style="text-align: center;">
      <div id="paypal-button-container" style="height: 36px"></div>
    </div>
  </div>
</template>

<script>

import { loadScript } from "@paypal/paypal-js";
export default {
  name: 'PayPalButton',
  mounted() {
    this.initButton()
  },
  methods: {
    async initButton() {
      let paypal

      try {
        paypal = await loadScript({
          'client-id': 'AYFYJ84ZVtJhkXQwSM4bgr69H2JQ_vN6znSv7gjn9hCOJaV6JvjB3DS8mp71cbv7JK7SlasG2JeTvtcf',
          'enable-funding': 'venmo',
          'data-sdk-integration-source': 'button-factory',
          'disable-funding': 'card,credit,bancontact',
          currency: 'USD',
          components: 'buttons' // buttons,marks,messages
        });
      } catch (error) {
        console.error("failed to load the PayPal JS SDK script", error);
      }

      if (paypal) {
        try {
          let btnOptions = {
            style: {
              shape: 'rect',
              color: 'gold',
              layout: 'vertical',
              label: 'paypal',

            },

            createOrder: function(data, actions) {
              return actions.order.create({
                purchase_units: [{"description":"Crowdfunding Gebühr","amount":{"currency_code":"USD","value":100}}]
              });
            },

            onApprove: function(data, actions) {
              return actions.order.capture().then(function(orderData) {

                // Full available details
                console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));

                // Show a success message within this page, e.g.
                const element = document.getElementById('paypal-button-container');
                element.innerHTML = '';
                element.innerHTML = '<h3>Thank you for your payment!</h3>';

                // Or go to another URL:  actions.redirect('thank_you.html');

              });
            },

            onError: function(err) {
              console.log(err);
            }
          }

          await paypal.Buttons(btnOptions)
            .render('#paypal-button-container');

        } catch (error) {
          console.error("failed to render the PayPal Buttons", error);
        }
      }
    }
  }
}
</script>

<style scoped>
.s-wrapper-btn {
  height: 36px;
  max-width: 250px;
  margin: 0 auto;
}
</style>
